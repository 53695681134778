body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Set the background image */
  background-image: url('background.png'); /* Replace with your image path */
  background-size: cover; /* Cover the entire window */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Fixed background */

  /* Using a pseudo-element to apply blur */
  position: relative;
  z-index: 1;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  /* Set the same background image */
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
